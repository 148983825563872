<template lang="pug">
div(class='font-bold') {{ texts.title }}
  slot
div {{ texts.other }}
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  address: { type: String, required: true },
})

const texts = computed(() => {
  const [title, ...other] = props.address.split(' | ')
  return { title, other: other.join(', ') }
})
</script>
