<template lang="pug">
modal(size='base' @close='$emit("close")' )
  generic-form(
    id='FormEditDeliverySetup'
    v-model='formDataModel'
    :endpoint='`/_/EditDeliverySetup?inquiryId=${inquiryId}`'
    updateOnSubmitSuccess="AddAddress,SaveAddresses"
    @submitSuccess='(e: IFormResponseInfo) => (e.action === "SaveDeliverySetup" && $emit("close"))'
    @beforeSubmit='beforeSubmit'
    @onReady='onFormReady'
  )
    template(#default='{loading, formData}')
      modal-header(icon='truck') {{ $t('Delivery Information') }}: !{' '} <em>{{ inquiryId }}</em>
      div(v-if='hasFormChanges' class='flex gap-1 items-center text-text-light' data-test='form-persist-message')
        span {{$t("The form has unsaved changes")}}
        btn(link data-test-btn='reset-form' data-test='btn-reset-form' @click='resetChanges') {{$t('Reset form')}}

      form-select(v-model:option='invoiceAddress' name='InvoiceAddress' :controlProps='{searchable: false}' class='pt-4')
        template(#item='{value,  label, selected, focused, focus, toggle}')
          select-item(:selected='selected'  :focused='focused' @click.prevent.stop='toggle()' @mousedown.prevent='focus')
            formatted-address(:address='label')
              pill(v-if='isDifferentCustomer(customerId)' class='ml-2')  {{ customerName }}
            template(#actions)
              btn(v-if="!isDifferentCustomer(customerId)" icon='custom-edit' data-test='btn-edit-address-from-dropdown'  faded @click.prevent.stop="editAddress(value, 'InvoiceAddress', label)")
        template(#footer)
          btn(class='my-3' data-test='btn-add-address-from-dropdown' link icon='plus' @mousedown="$utils.routeQueryAdd({ 'form-inquiry-address-add': { id: inquiryId, title: inquiryId, context: 'Inquiry', type: 'InvoiceAddress' } })")
            | {{$t('Add address')}}

      form-select(v-show='!isSameAddress'  v-model:option='deliveryAddress' name='DeliveryAddress' :controlProps='{searchable: false}')
        template(#item='{value, label, selected, focused, focus, toggle}')
          select-item(:selected='selected' :focused='focused' @click.prevent.stop='toggle()' @mousedown.prevent='focus')
            formatted-address(:address='label')
              pill(v-if='isDifferentCustomer(customerId)')  {{ customerName }}
            template(#actions)
              btn(v-if="!isDifferentCustomer(customerId)" icon='custom-edit' data-test='btn-edit-address-from-dropdown'  faded @click.prevent.stop="editAddress(value, 'DeliveryAddress', label)")
        template(#footer)
          btn(class='my-3' link icon='plus' data-test='btn-add-address-from-dropdown'  @mousedown="$utils.routeQueryAdd({ 'form-inquiry-address-add': { id: inquiryId, title: inquiryId, context: 'Inquiry', type: 'DeliveryAddress' } })")
            | {{$t('Add address')}}
      div(class='mb-2 ml-1')
        form-checkbox(name='DeliveryAddressIsInvoiceAddress')
          | {{ $t('Use invoice address as delivery address') }}
      div(class='mt-0')
        p(class='mb-2 font-bold text-[15px]') {{ $t('contactPerson') }}
        div(class='grid grid-cols-2 gap-2 mb-3')
          form-input(v-model='contactPerson' name='ContactPerson' :label='$t("Name ")')
          form-input(v-model='contactPersonPhone' name='ContactPersonPhone' :label='$t("contactPersonPhone")')
        p(class='mb-2 font-bold   text-[15px]') {{ $t('deliverySetupType') }}
        form-radio-group(name='DeliverySetupTypes' class='mb-4' modelValue='standardDelivery')
          control-radio-item(id="DeliverySetupTypes_standardDelivery" key='standardDelivery' value='standardDelivery' :bordered='true' :centered='true' size='large' )
            div(v-html='$t("standardDelivery")')
            div(class='prose  !max-w-none text-sm text-text-light' v-html="$t('deliverySetupTypes.standardDelivery.description')")
          control-radio-item(id="DeliverySetupTypes_extendedService" key='extendedService' value='extendedService' :bordered='true' :centered='true' size='large' )
            div(v-html='$t("extendedService")')
            div(class='prose  !max-w-none text-sm text-text-light' v-html="$t('deliverySetupTypes.extendedService.description')")

        //- form-select-native(name='DeliverySetupTypes' :label='$t("deliverySetupType")')
        //- transition-expand
        //-   notification(v-if='formData.DeliverySetupTypes === "standardDelivery"' :showIcon='true' :message="$t('deliverySetupTypes.standardDelivery.description')")
        div(class='pl-8' style='border-left: 10px solid #f5f5f5')
          transition-expand
            div(v-if='formData.DeliverySetupTypes === "extendedService"'  )
              //- notification(class='mb-4 !pt-0' :showIcon='true' :message='$t("deliverySetupTypes.extendedService.description")')
              form-input-date(name='DesiredDeliveryDate' :label='$t("desiredDeliveryDate")'  :markOptional='true'  class='-mb-4')
              notification(class='mb-4' :showIcon='false')
                translation(id='desiredDeliveryDate.description')
              form-select-native(name='ServiceLevels' :label='$t("serviceLevel")' class='-mb-4')
          transition-expand
            div(v-if='formData.ServiceLevels')
              notification(class='mb-4' :showIcon='false')
                translation(v-if='formData.ServiceLevels === "combinedDeliveryFreeCurb"' id='combinedDeliveryFreeCurb.description')
                translation(v-else-if='formData.ServiceLevels === "combinedDeliveryWithCarrying"' id='combinedDeliveryWithCarrying.description')
                translation(v-else-if='formData.ServiceLevels === "pickupFromWarehouse"' id='pickupFromWarehouse.description')
                translation(v-else id='combinedDeliveryWithInstallation.description')
              form-radio(v-if='formData.ServiceLevels != "pickupFromWarehouse"' name='TruckParkingPossible' :label='$t("truckParkingPossible")' class='pt-2' :controlProps='{options: [{value: "true", label: $t("yes")}, {value: "false", label: $t("no")}]}')
              transition-expand
                div(v-if='formData.TruckParkingPossible === "false"')
                  form-input(name='TruckParkingInfo' :label='$t("truckParkingInfo")')
              form-radio(v-if='formData.ServiceLevels != "pickupFromWarehouse"' name='NoStoppingZone' :label='$t("noStoppingZone")' class='pt-2' :controlProps='{options: [{value: "true", label: $t("yes")}, {value: "false", label: $t("no")}]}')
              transition-expand
                div(v-if='formData.ServiceLevels === "combinedDeliveryWithCarrying" || formData.ServiceLevels === "combinedDeliveryWithInstallation"')
                  form-input(name='Floor' :label='$t("floor")')
                  form-radio(name='Elevator' :label='$t("elevator")' class='pt-2' :controlProps='{options: [{value: "true", label: $t("yes")}, {value: "false", label: $t("no")}]}')
                  transition-expand
                    div(v-if='formData.Elevator === "true"')
                      form-input(name='ElevatorDimensions' :markOptional='true' :label='$t("elevatorDimensions")')
              form-textarea(name='Description' :markOptional='true' :label='$t("description.label")')
              notification(class='-mt-3' :showIcon='false' :message='$t("description.description")')

      form-footer(class='pt-8')
        template(#secondary)
          btn(v-if='$flags.ENABLE_HELPCENTER' icon='help-circle' link  @click="$helpcenter.open('inquiries-delivery-costs')" )
            translation(id='link.helpcenter.deliveryPricingInfo')
        form-action(name='SaveDeliverySetup' :label='$t("saveDeliverySetup")' :primary='true' medium formActionName='EditDeliverySetupForm/SaveDeliverySetup' :loading='loading' class='font-bold')
</template>

<script setup lang="ts">
import GenericForm from '@/components/GenericForm/GenericForm.vue'
import { useFormDataPersist } from '@/components/GenericForm/composables'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import Notification from '@/components/Notification/Notification.vue'
import SelectItem from '@/components/SelectItem.vue'
import { useOnSuccess, thorFormPostPromise } from '@/composables/'
import { useCustomer } from '@/pages/customer/components/index'
import { useModals } from '@/plugins/Modals'
import { type IAddress, useUser } from '@/plugins/User'
import { type IOption } from '@/types'
import { computed, watch, ref } from 'vue'

const props = defineProps({
  inquiryId: { required: true, type: String },
  customerId: { required: true, type: String },
  customerName: { required: true, type: String },
})
defineOptions({
  inheritAttrs: false,
})
const { resetChanges, onFormReady, hasFormChanges, formDataModel } = useFormDataPersist(props.inquiryId, true, [
  'DeliveryAddressIsInvoiceAddress',
])

const $user = useUser()
const $modals = useModals()

const { customer, load: loadCustomer } = useCustomer(computed(() => $user.myCustomer!))
loadCustomer()

useOnSuccess(['AddAddress', 'UpdateAddress'], loadCustomer)
useOnSuccess(['UpdateAddress'], (data) => {
  thorFormPostPromise(`/_/EditInquiryAddress/EditInquiryAddressForm/SaveAddresses?inquiryId=${props.inquiryId}`, {
    [data.formData.Type]: data.formData.AddressIdentifier,
  })
})

const contactPerson = ref('')
const contactPersonPhone = ref('')
const deliveryAddress = ref<IOption<IAddress> | null>(null)
const invoiceAddress = ref<IOption<IAddress> | null>(null)
const isSameAddress = computed(() => formDataModel.value.DeliveryAddressIsInvoiceAddress === 'true')

const updateContactPersonIfNotAlreadySet = (address: IAddress) => {
  if (!contactPerson.value && address?.contactPerson) {
    contactPerson.value = `${address!.contactPerson}`
  }
  if (!contactPersonPhone.value && address?.phone) {
    contactPersonPhone.value = `${address!.phone}`
  }
}

watch(
  () => deliveryAddress.value,
  (address) => address?.data && updateContactPersonIfNotAlreadySet(address!.data!),
  { deep: true },
)

watch(
  () => invoiceAddress.value,
  (address) => isSameAddress.value && address?.data && updateContactPersonIfNotAlreadySet(address!.data!),
  { deep: true },
)

watch(
  () => isSameAddress.value,
  (value) => value && invoiceAddress.value?.data && updateContactPersonIfNotAlreadySet(invoiceAddress.value!.data!),
  { deep: true },
)

const isCustomerAddress = (addressId: string) => !!customer.value?.addresses?.some((a) => a.id === addressId)
const isDifferentCustomer = (customerId?: string) => customerId && customerId !== customer.value?.id

const editAddress = (addressId: string, type: 'InvoiceAddress' | 'DeliveryAddress', label = '') =>
  isCustomerAddress(addressId)
    ? $modals.open('form-customer-address-edit', { id: addressId, customerId: $user.myCustomer, type })
    : $modals.open('form-inquiry-address-update', { id: props.inquiryId, title: label, context: 'Inquiry', type })

// if same address checkbox set delivery address to invoice address before submit
const beforeSubmit = () =>
  isSameAddress.value && (formDataModel.value['DeliveryAddress'] = formDataModel.value.InvoiceAddress)
</script>
