<template lang="pug">
div(v-html='$t(id, hint, placeholders)')
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

const Translation = defineComponent({
  props: {
    id: { type: String, required: true },
    hint: { type: String, default: '' },
    placeholders: { type: Array as PropType<string[]> },
  },
})

export default Translation
</script>
