<template lang="pug">
div(plain :loading='false' :class='{ "select-item--selected": selected, "select-item--focused": focused }' class='select-item')
  div(class='select-item__content')
    slot
  div(class='select-item__checkIcon')
    icon(v-if='selected' name='check')
  div(class='select-item__actions')
    slot(name='actions')
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

const SelectItem = defineComponent({
  components: { Icon },
  props: {
    focused: Boolean,
    selected: Boolean,
  },
})

export default SelectItem
</script>

<style lang="stylus">
@import '../styles/variables.styl'
:root
  --select-item-padding-y: 18px
  --select-item-padding-x: 14px
.select-item
  padding-right: 30px
  display: flex!important
  align-items: center
  transition: none
  max-width: 100%
  padding: var(--select-item-padding-y) var(--select-item-padding-x)
  cursor: pointer
  margin: 3px 6px
  &:hover,
  &--focused,
  &--selected
    border-radius: 6px
    background-color: var(--blue-400-10)!important
  &--selected
    background-color: var(--blue-400-10)!important
  &__actions
    ~/:hover &
      opacity: 1
  &__content
    flex: 1
    min-width: 0
    line-height: 1.5
    -webkit-font-smoothing: antialiased
    ~/[nowrap] &
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  &__checkIcon
    .icon
      background: var(--color-accent)
      color: #fff
      padding: 5px
      border-radius: 20px
      font-size: 26px
      aspect-ratio: 1
      transform: translateY(2px)
      width: 30px
      height: 30px
</style>
